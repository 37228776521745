export var ARCHIVE_PARAMS = {
    showNewestPostsFirst: {
        wixParam: 'showNewestPostsFirst',
        appSettingsPath: 'style.booleans.showNewestPostsFirst',
        defaultValue: true,
    },
    showPostCount: {
        wixParam: 'showPostCount',
        appSettingsPath: 'style.booleans.showPostCount',
        defaultValue: true,
    },
    monthsDisplayLimit: {
        wixParam: 'monthsDisplayLimit',
        appSettingsPath: 'style.numbers.monthsDisplayLimit',
        defaultValue: 12,
        minValue: 1,
        maxValue: 50,
    },
};
export var ARCHIVE_PARAMS_MOBILE = {
    showNewestPostsFirst: {
        wixParam: 'showNewestPostsFirstMobile',
        appSettingsPath: 'style.booleans.showNewestPostsFirstMobile',
        defaultValue: true,
    },
    showPostCount: {
        wixParam: 'showPostCountMobile',
        appSettingsPath: 'style.booleans.showPostCountMobile',
        defaultValue: true,
    },
    monthsDisplayLimit: {
        wixParam: 'monthsDisplayLimitMobile',
        appSettingsPath: 'style.numbers.monthsDisplayLimitMobile',
        defaultValue: 12,
        minValue: 1,
        maxValue: 50,
    },
    isMobileDisplaySettingsEnabled: {
        wixParam: 'archive-mobile-isDisplaySettingsEnabled',
        appSettingsPath: 'style.booleans.archive-mobile-isDisplaySettingsEnabled',
        defaultValue: false,
    },
    isMobileDesignSettingsEnabled: {
        wixParam: 'archive-mobile-isDesignSettingsEnabled',
        appSettingsPath: 'style.booleans.archive-mobile-isDesignSettingsEnabled',
        defaultValue: false,
    },
    isMobileLayoutSettingsEnabled: {
        wixParam: 'archive-mobile-isLayoutSettingsEnabled',
        appSettingsPath: 'style.booleans.archive-mobile-isLayoutSettingsEnabled',
        defaultValue: false,
    },
};
