import React from 'react';
import { flowRight } from 'lodash';

import { connect } from '../../../common/components/runtime-context';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import { getLanguage } from '../../../common/store/basic-params/basic-params-selectors';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';
import AppLoaded from '../../../common/components/app-loaded';
import Archive from '../archive';
import styles from './app-root.scss';

const AppRoot = () => {
  return (
    <ResponsiveListener>
      <div className={styles.baseStyles}>
        <Archive />
        <AppLoaded />
      </div>
    </ResponsiveListener>
  );
};

const mapRuntimeToProps = (state) => {
  return {
    language: getLanguage(state),
    translations: getTranslations(state),
    sectionUrl: getSectionUrl(state),
  };
};

export default flowRight(withReduxStore, connect(mapRuntimeToProps))(AppRoot);
