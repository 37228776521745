import { omit, trimStart } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { BLOG_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import {
  SECTION_BLOG_PAGE,
  SECTION_BLOG_POST_PAGE,
  isExperimentEnabled,
  getInternalLinkRoute,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_PROD_OOI_EDITOR } from '@wix/communities-blog-experiments';

import { connect } from '../runtime-context';
import { isSite } from '../../store/basic-params/basic-params-selectors';
import { getTopology } from '../../store/topology/topology-selectors';
import { isAppRoute } from '../../services/section-route-utils';
import Wix from '../../services/wix-sdk-polyfill';
import { getPathWithoutSectionUrl } from '../../services/get-path-without-section-url';

import styles from './internal-link.scss';

const OMIT_PROPS = [
  'dispatch',
  'titleFontClassName',
  'tReady',
  'lng',
  'contentFontClassNameWithStyle',
];

const InternalLink = ({
  addHoverClasses,
  children,
  className,
  isSite,
  sectionId: section,
  to,
  topology,
  useCustomRouter,
  navigateInPreview,
  fullRoute,
  isExperimentProdOOIEditorEnabled,
  navigateWithinBlog,
  navigateWithoutScroll,
  isActive,
  onNavigation,
  ...props
}) => {
  const shouldNavigateToFeed = section === SECTION_BLOG_PAGE || isAppRoute(to);
  const sectionId = shouldNavigateToFeed
    ? SECTION_BLOG_PAGE
    : SECTION_BLOG_POST_PAGE;
  const route =
    fullRoute ||
    getInternalLinkRoute({
      topology,
      to,
      shouldNavigateToFeed,
    });
  const linkClassName = classNames(
    addHoverClasses && 'blog-link-hover-color',
    className,
    navigateWithoutScroll && styles.noScrollButton,
  );
  const linkProps = omit(props, OMIT_PROPS);

  const handleClick = (event) => {
    if (!isSite) {
      event.preventDefault();
      if (isExperimentProdOOIEditorEnabled) {
        Wix.Utils.navigateToSection(
          {
            appDefinitionId: BLOG_APP_ID,
            sectionId,
            shouldRefreshIframe: false,
          },
          trimStart(route, '/'),
        );
      } else {
        navigateInPreview(route, sectionId);
      }
    } else if (navigateWithoutScroll) {
      navigateWithinBlog(
        getPathWithoutSectionUrl({
          route,
          topology,
          shouldNavigateToFeed,
        }),
        {
          disableScrollToTop: true,
        },
      );
    }
    if (onNavigation) {
      onNavigation();
    }
  };

  if (navigateWithoutScroll) {
    return (
      <button
        aria-current={isActive ? 'page' : undefined}
        onClick={handleClick}
        className={linkClassName}
        {...linkProps}
      >
        {children}
      </button>
    );
  }

  return (
    <a
      href={route}
      onClick={handleClick}
      className={linkClassName}
      {...linkProps}
    >
      {children}
    </a>
  );
};

InternalLink.propTypes = {
  push: PropTypes.func,
  to: PropTypes.string,
  children: PropTypes.node,
  addHoverClasses: PropTypes.bool,
  topology: PropTypes.object,
  isSite: PropTypes.bool,
  sectionId: PropTypes.string,
  navigateInPreview: PropTypes.func.isRequired,
  navigateWithinBlog: PropTypes.func.isRequired,
  isExperimentProdOOIEditorEnabled: PropTypes.bool,
  navigateWithoutScroll: PropTypes.bool,
  isActive: PropTypes.bool,
};

InternalLink.defaultProps = {
  addHoverClasses: true,
};

const mapRuntimeToProps = (state, _ownProps, actions) => ({
  topology: getTopology(state),
  isSite: isSite(state),
  navigateInPreview: actions.navigateInPreview,
  navigateWithinBlog: actions.navigateWithinBlog,
  isExperimentProdOOIEditorEnabled: isExperimentEnabled(
    state,
    EXPERIMENT_PROD_OOI_EDITOR,
  ),
});

export default connect(mapRuntimeToProps)(InternalLink);
