export var LINK_LIST_TEXT_ALIGNMENT = {
    left: 0,
    center: 1,
    right: 2,
};
export var LINK_LIST_DESIGN_PARAMS = {
    textFont: {
        wixParam: 'textFont',
        defaultTheme: 'font_8',
        defaultSize: 15,
        minSize: 6,
        maxSize: 176,
        appSettingsPath: 'style.fonts.textFont',
    },
    textColor: {
        wixParam: 'textColor',
        defaultColor: 'color-5',
        appSettingsPath: 'style.colors.textColor',
    },
    textColorHover: {
        wixParam: 'textColorHover',
        defaultColor: 'color-5',
        appSettingsPath: 'style.colors.textColorHover',
    },
    backgroundColor: {
        wixParam: 'backgroundColor',
        defaultColor: 'color-1',
        defaultOpacity: 1,
        appSettingsPath: 'style.colors.backgroundColor',
    },
    backgroundColorHover: {
        wixParam: 'backgroundColorHover',
        defaultColor: 'color-2',
        defaultOpacity: 0.75,
        appSettingsPath: 'style.colors.backgroundColorHover',
    },
    separatorColor: {
        wixParam: 'separatorColor',
        defaultColor: 'color-2',
        defaultOpacity: 0.75,
        appSettingsPath: 'style.colors.separatorColor',
    },
    separatorWidth: {
        wixParam: 'separatorWidth',
        defaultWidth: 0,
        minWidth: 0,
        maxWidth: 15,
        appSettingsPath: 'style.numbers.separatorWidth',
    },
    borderColor: {
        wixParam: 'borderColor',
        defaultColor: 'color-2',
        defaultOpacity: 0.75,
        appSettingsPath: 'style.colors.borderColor',
    },
    borderWidth: {
        wixParam: 'borderWidth',
        defaultWidth: 1,
        minWidth: 0,
        maxWidth: 15,
        appSettingsPath: 'style.numbers.borderWidth',
    },
    cornerRadius: {
        wixParam: 'cornerRadius',
        defaultRadius: 0,
        minRadius: 0,
        maxRadius: 100,
        appSettingsPath: 'style.numbers.cornerRadius',
    },
};
export var LINK_LIST_MOBILE_DESIGN_PARAMS = {
    textFont: {
        wixParam: 'textFontMobile',
        defaultTheme: 'font_8',
        defaultSize: 15,
        minSize: 6,
        maxSize: 176,
        appSettingsPath: 'style.fonts.textFontMobile',
    },
    textColor: {
        wixParam: 'textColorMobile',
        defaultColor: 'color-5',
        appSettingsPath: 'style.colors.textColorMobile',
    },
    textColorHover: {
        wixParam: 'textColorHoverMobile',
        defaultColor: 'color-5',
        appSettingsPath: 'style.colors.textColorHoverMobile',
    },
    backgroundColor: {
        wixParam: 'backgroundColorMobile',
        defaultColor: 'color-1',
        defaultOpacity: 1,
        appSettingsPath: 'style.colors.backgroundColorMobile',
    },
    backgroundColorHover: {
        wixParam: 'backgroundColorHoverMobile',
        defaultColor: 'color-2',
        defaultOpacity: 0.75,
        appSettingsPath: 'style.colors.backgroundColorHoverMobile',
    },
    separatorColor: {
        wixParam: 'separatorColorMobile',
        defaultColor: 'color-2',
        defaultOpacity: 0.75,
        appSettingsPath: 'style.colors.separatorColorMobile',
    },
    separatorWidth: {
        wixParam: 'separatorWidthMobile',
        defaultWidth: 0,
        minWidth: 0,
        maxWidth: 15,
        appSettingsPath: 'style.numbers.separatorWidthMobile',
    },
    borderColor: {
        wixParam: 'borderColorMobile',
        defaultColor: 'color-2',
        defaultOpacity: 0.75,
        appSettingsPath: 'style.colors.borderColorMobile',
    },
    borderWidth: {
        wixParam: 'borderWidthMobile',
        defaultWidth: 1,
        minWidth: 0,
        maxWidth: 15,
        appSettingsPath: 'style.numbers.borderWidthMobile',
    },
    cornerRadius: {
        wixParam: 'cornerRadiusMobile',
        defaultRadius: 0,
        minRadius: 0,
        maxRadius: 100,
        appSettingsPath: 'style.numbers.cornerRadiusMobile',
    },
};
export var LINK_LIST_LAYOUT_PARAMS = {
    textAlignment: {
        wixParam: 'textAlignment',
        appSettingsPath: 'style.numbers.textAlignment',
        defaultAlignment: LINK_LIST_TEXT_ALIGNMENT.left,
    },
    textPaddingHorizontal: {
        wixParam: 'textPaddingHorizontal',
        appSettingsPath: 'style.numbers.textPaddingHorizontal',
        defaultPadding: 12,
        minPadding: 0,
        maxPadding: 30,
    },
    textPaddingVertical: {
        wixParam: 'textPaddingVertical',
        appSettingsPath: 'style.numbers.textPaddingVertical',
        defaultPadding: 12,
        minPadding: 0,
        maxPadding: 30,
    },
};
export var LINK_LIST_LAYOUT_MOBILE_PARAMS = {
    textAlignment: {
        wixParam: 'textAlignmentMobile',
        appSettingsPath: 'style.numbers.textAlignmentMobile',
        defaultAlignment: LINK_LIST_TEXT_ALIGNMENT.left,
    },
    textPaddingHorizontal: {
        wixParam: 'textPaddingHorizontalMobile',
        appSettingsPath: 'style.numbers.textPaddingHorizontalMobile',
        defaultPadding: 12,
        minPadding: 0,
        maxPadding: 30,
    },
    textPaddingVertical: {
        wixParam: 'textPaddingVerticalMobile',
        appSettingsPath: 'style.numbers.textPaddingVerticalMobile',
        defaultPadding: 12,
        minPadding: 0,
        maxPadding: 30,
    },
};
